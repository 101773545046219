import { PlusIcon, RefreshIcon, CogIcon } from "@heroicons/react/solid";
import Link from "next/link";
import useStore from "../store/store";
import { routes } from "../utils/constants";
import MailErrorModal from "./MailErrorModal";
import AdminFormErrorModal from "./AdminFormErrorModal";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import LogoModal from "./LogoModal";
import { formatMyCompany } from "../utils/company";

type LayoutPropsType = {
  children: React.ReactNode;
  currentPage: string;
};

const Planning = ({ children, currentPage }: LayoutPropsType) => {
  const {
    admin,
    myCompany,
    loading,
    setLoading,
    loadNewData,
    dataLoaded,
    logo,
  } = useStore();
  return (
    <>
      {loading && (
        <div className="bg-background-transparent fixed flex h-full items-center justify-center opacity-50 w-full z-50 cursor-wait"></div>
      )}
      <header className="bg-blue">
        <div className="content flex items-center justify-between py-4 mx-4">
          <nav className="flex items-center text-white gap-3">
            <h1 className="font-semibold text-lg mr-3">
              {logo ? (
                <img
                  alt="companylogo"
                  style={{ height: "36px" }}
                  src={logo}
                ></img>
              ) : (
                formatMyCompany(myCompany)
              )}
            </h1>

            <Link href={routes.employees}>
              <a
                className={
                  currentPage === "employees" && dataLoaded
                    ? "text-dark-grey bg-white p-2.5 rounded-lg font-bold text-xs uppercase"
                    : "p-2.5 font-bold text-xs uppercase"
                }
              >
                Medewerkers
              </a>
            </Link>
            <Link href="/">
              <a
                className={
                  currentPage === "planning" && dataLoaded
                    ? "text-dark-grey bg-white p-2.5 rounded-lg font-bold text-xs uppercase"
                    : "p-2.5 font-bold text-xs uppercase"
                }
              >
                Planning
              </a>
            </Link>

            <Link href="/timesheet">
              <a
                className={
                  currentPage === "timesheet" && dataLoaded
                    ? " text-dark-grey bg-white p-2.5 rounded-lg font-bold text-xs uppercase"
                    : "p-2.5 font-bold text-xs uppercase"
                }
              >
                Prestaties
              </a>
            </Link>

            <Link href="/clock">
              <a
                className={
                  currentPage === "clock" && dataLoaded
                    ? "text-dark-grey bg-white p-2.5 rounded-lg font-bold text-xs uppercase"
                    : "p-2.5 font-bold text-xs uppercase"
                }
              >
                Prikklok
              </a>
            </Link>

            {admin && (
              <Link href="/admin">
                <a
                  className={
                    currentPage === "admin" && dataLoaded
                      ? "text-dark-grey bg-white p-2.5 rounded-lg font-bold text-xs uppercase"
                      : "p-2.5 font-bold text-xs uppercase"
                  }
                >
                  Admin
                </a>
              </Link>
            )}
          </nav>
          <div className="flex items-center">
            <Link href="/documentation">
              <a
                className="mr-5"
                data-tip="Hier kun je de documentatie bekijken"
                data-for="buttonTitle"
              >
                <QuestionMarkCircleIcon
                  className="pointer-events-none"
                  height={20}
                  color="white"
                ></QuestionMarkCircleIcon>
              </a>
            </Link>
            <button
              className="mr-5"
              onClick={() => {
                //@ts-ignore
                document.querySelector("#logoModal")?.showModal();
              }}
            >
              <CogIcon height={20} color="white"></CogIcon>
            </button>
            <button
              className="mr-5"
              onClick={async (e) => {
                setLoading(true);
                //@ts-ignore
                e.target.classList.add("animate-spin");
                await loadNewData();
                //@ts-ignore
                e.target.classList.remove("animate-spin");
                setLoading(false);
              }}
              data-tip="Data ophalen"
              data-for="buttonTitle"
            >
              <RefreshIcon
                className="pointer-events-none"
                height={20}
                color="white"
              ></RefreshIcon>
            </button>
            <a
              className="text-white mr-5"
              href={encodeURI(
                (process.env.NEXT_PUBLIC_SITE_URL ===
                "https://planning.digitalpayrollservices.be/"
                  ? "https://sparrow.auth"
                  : "https://sparrow-antenna.auth") +
                  ".eu-central-1.amazoncognito.com/logout?client_id=" +
                  (process.env.NEXT_PUBLIC_SITE_URL ===
                  "https://planning.digitalpayrollservices.be/"
                    ? "5frq2b4lrreg3s61s3evc24ncm"
                    : "5kq14hqdk5dh7m8j5ivjqjhvok") +
                  "&logout_uri=" +
                  (process.env.NEXT_PUBLIC_SITE_URL ||
                    "http://localhost:3000/") +
                  "loggedout"
              )}
            >
              Afmelden
            </a>
            {currentPage === "employees" && (
              <Link href={routes.employeesRegister}>
                <a>
                  <button
                    type="button"
                    className="p-2.5 flex items-center bg-red rounded-lg text-white font-bold text-xs uppercase"
                  >
                    <PlusIcon height={20} className="mr-1"></PlusIcon>
                    Medewerker inschrijven
                  </button>
                </a>
              </Link>
            )}
            {currentPage === "planning" && (
              <button
                className="p-2.5 flex items-center bg-red rounded-lg text-white font-bold text-xs uppercase"
                onClick={() => {
                  //@ts-ignore
                  document.getElementById("addTab")?.showModal();
                }}
                type="button"
              >
                <PlusIcon height={20} className="mr-1"></PlusIcon>
                Voeg tabblad toe
              </button>
            )}
            {currentPage === "timesheet" && (
              <button
                className="p-2.5 flex items-center bg-red rounded-lg text-white font-bold text-xs uppercase"
                type="button"
              >
                <PlusIcon height={20} className="mr-1"></PlusIcon>
                Add project
              </button>
            )}
            {currentPage === "admin" && (
              <Link href={routes.addCompany}>
                <a>
                  <button
                    type="button"
                    className="p-2.5 flex items-center bg-red rounded-lg text-white font-bold text-xs uppercase"
                  >
                    <PlusIcon height={20} className="mr-1"></PlusIcon>
                    Bedrijf toevoegen
                  </button>
                </a>
              </Link>
            )}
          </div>
        </div>
      </header>
      <MailErrorModal></MailErrorModal>
      <AdminFormErrorModal></AdminFormErrorModal>
      <LogoModal></LogoModal>
      {children}
    </>
  );
};

export default Planning;
