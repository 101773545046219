import { FormEvent, useEffect, useState } from "react";
import dialogPolyfill from "../utils/diaglog-polyfill";
import { format } from "date-fns";
import { ClassNames, DayPicker } from "react-day-picker";
import styles from "react-day-picker/dist/style.css";
import useStore from "../store/store";
import dayjs from "dayjs";
import { toBase64 } from "../utils";

const LogoModal = () => {
  const { saveLogo, setLoading } = useStore();
  useEffect(() => {
    var dialog = document.querySelector("#logoModal");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);
  const [logo, setLogo] = useState("");

  const submitForm = async () => {
    setLoading(true);
    saveLogo(logo);
    setLogo("")
  };

  return (
    <dialog
      id="logoModal"
      className="border-none rounded-xl py-5 px-10 min-w-[400px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">Settings</h3>
        <button
          className="text-3xl"
          onClick={() => {
            //@ts-ignore
            document.getElementById("logoModal")!.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>

      <form
        method="dialog"
        onSubmit={submitForm}
        className="flex-col items-center justify-center "
      >
        <label
          className="mt-3 inline-block btn-secondary cursor-pointer"
          htmlFor="logo"
        >
          Selecteer logo (max 300kb)
        </label>
        <div>
          <input
            onChange={async (e: any) => {
              if (e.target.files[0].size > 350235) {
                alert("Logo is too big");
                e.target.value = "";
                return;
              }
              const data = await toBase64(e.target.files[0]);
              if (typeof data == "string") {
                setLogo(data);
                e.target.value = "";
              }
            }}
            type="file"
            name="logo"
            className="opacity-0"
            accept="image/*"
            id="logo"
          />
        </div>
        {logo && (
          <>
            <div className="bg-blue inline-block p-5">
              <img
                style={{ height: "36px" }}
                src={logo}
                alt="companyLogo"
              ></img>
            </div>
          </>
        )}
        <div className="flex justify-end pt-4">
          <button className="btn-primary">Sla logo op</button>
        </div>
      </form>
    </dialog>
  );
};

export default LogoModal;
