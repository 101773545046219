import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import dialogPolyfill from "../utils/diaglog-polyfill";

const AdminFormErrorModal = () => {
  useEffect(() => {
    var dialog = document.querySelector("#adminError");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  return (
    <dialog
      id="adminError"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">Er is een fout opgetreden</h3>
        <button
          className="text-3xl"
          onClick={() => {
            //@ts-ignore
            document.getElementById("adminError")?.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>

      <div className="flex justify-center items-center flex-col">
        <ExclamationCircleIcon className="text-red h-64 mt-7"></ExclamationCircleIcon>
        <p className="text-center text-blue mt-7">
          Het opgegeven e-mail adres is al in gebruik. Gelieve een ander e-mail
          adres te gebruiken.
        </p>
      </div>
    </dialog>
  );
};

export default AdminFormErrorModal;
