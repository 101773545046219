import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { FormEvent, useEffect, useState } from "react";
import useStore from "../store/store";
import dialogPolyfill from "../utils/diaglog-polyfill";

const MailErrorModal = () => {
  useEffect(() => {
    var dialog = document.querySelector("#emailSendError");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  return (
    <dialog
      id="emailSendError"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">
          De emails konden niet worden verzonden
        </h3>
        <button
          className="text-3xl"
          onClick={() => {
            //@ts-ignore
            document.getElementById("emailSendError")?.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>

      <div className="flex justify-center items-center flex-col">
        <ExclamationCircleIcon className="text-red h-64 mt-7"></ExclamationCircleIcon>
        <p className="text-center text-blue mt-7">
          Er is een interne fout opgetreeden waardoor de gevraagde emails niet
          konden verzonden worden.
          <br /> contacteer de systeembeheerder indien het probleem blijft
          aanhouden.
        </p>
      </div>
    </dialog>
  );
};

export default MailErrorModal;
